<template>
  <div>
    <v-col cols="12" class="primary mt-4 mx-0 pa-6 white--text">
      <v-row>
        <div class="text-uppercase font-weight-bold">approved loans</div>
      </v-row>
      <v-row class="">
        <div class="">
          Lorem ipsum dolor sit, amet consectetur adipisicing elit. Aliquid enim
          eius tempore natus! Neque dolore explicabo aliquid inventore
          consequuntur vitae illum ratione doloribus quo, cum in, soluta
          perferendis laboriosam qui.
        </div>
      </v-row>
    </v-col>

    <div class="pa-2">
      <div class="d-flex">
        <v-spacer></v-spacer>
        <v-spacer></v-spacer>
        <v-text-field
          label="Search"
          outlined
          dense
          v-model="search"
          append-icon="mdi-magnify"
        ></v-text-field>
      </div>
      <v-data-table
        class="text-capitalize elevation-10"
        :search="search"
        :items="items"
        :headers="headers"
        single-expand
        show-expand
        item-key="member"
      >
        <template v-slot:[`item.loans`]="{ item }">
          {{ item.loans.length }}
        </template>
        <template v-slot:expanded-item="{ item }">
          <v-list two-line>
            <v-list-item v-for="loan in item.loans" :key="loan.type">
              <v-list-item-icon>
                <v-icon> mdi-circle-small </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  {{ loan.type }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  KES {{ loan.amount }}
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-btn
                  outlined
                  color="primary"
                  rounded
                  small
                  @click="approveDialog = true"
                >
                  deny
                </v-btn>
              </v-list-item-action>
            </v-list-item>
            <v-divider inset></v-divider>
          </v-list>
        </template>
      </v-data-table>

      <v-dialog v-model="approveDialog" width="auto">
        <v-card width="500">
          <v-card-title>
            <p class="text-uppercase mx-auto">approve/deny loan</p>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text class="mt-3">
            <v-form>
              <v-autocomplete
                label="Status"
                outlined
                :items="['Approved', 'Denied']"
                dense
                v-model="markStatus"
              ></v-autocomplete>

              <v-textarea
                label="Reason"
                outlined
                dense
                v-model="reason"
              ></v-textarea>

              <v-btn block color="primary">submit</v-btn>
            </v-form>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      receiptDialog: false,
      approveDialog: false,
      markStatus: "",
      reason: "",
      search: "",
      headers: [
        {
          text: "date",
          value: "date",
          class: "text-uppercase black--text",
        },
        {
          text: "member",
          value: "member",
          class: "text-uppercase black--text",
        },
        {
          text: "loan types",
          value: "loans",
          class: "text-uppercase black--text",
        },
      ],
      items: [
        {
          date: "2nd February 2022",
          member: "david kimani",
          loans: [
            {
              type: "car loan",
              amount: "2,343,344",
            },
            {
              type: "mortgage loan",
              amount: "5,333,632",
            },
            {
              type: "salary loan",
              amount: "500,432",
            },
          ],
        },
        {
          date: "2nd February 2022",
          member: "simon kimani",
          loans: [
            {
              type: "car loan",
              amount: "2,343,344",
            },
            {
              type: "mortgage loan",
              amount: "5,333,632",
            },
            {
              type: "salary loan",
              amount: "500,432",
            },
          ],
        },
        {
          date: "2nd February 2022",
          member: "amos kimani",
          loans: [
            {
              type: "car loan",
              amount: "2,343,344",
            },
            {
              type: "mortgage loan",
              amount: "5,333,632",
            },
            {
              type: "salary loan",
              amount: "500,432",
            },
          ],
        },
      ],
    };
  },
};
</script>

<style></style>
